body,
html {
    overflow-x: hidden;
    background: black;
    font-family: 'roboto_condensedlight';

}

* {
    outline: none !important;
    scroll-behavior: smooth;
}

header {
    position: relative;
    z-index: 1000000;
}

p {
    font-size: 1.15em;
}

.navbar {
    // background: transparent !important;
    background: rgba(0, 0, 0, 0.753) !important;
    transition: 1s;

    .fas {
        color: rgb(68, 182, 102);
        font-size: 1.2rem;
        letter-spacing: 3px;
        margin-right: 30px;

    }

    .phone {
        color: white;
        margin-bottom: 0;
        margin-right: 50px;
        font-size: 20px;
    }

    .location {
        color: white;
        margin-bottom: 0;
        margin-right: 50px;
        font-size: 20px;
    }
}

.scrolled {
    background: rgba(0, 0, 0, 0.753) !important;
    // background: rgba(255, 0, 149, 0.623) !important;
    transition: 1s;
}

// .navbar-nav {
//     background: rgb(255, 0, 149);
// }

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgb(255, 0, 149);
    // background:rgb(255, 0, 149);
    border: 1px solid rgb(255, 0, 149);
    border-radius: 5px;
}

.navbar-light .navbar-nav .active>.nav-link:hover {
    color: #000;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 8);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgb(255, 0, 149);
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:visited {
    color: rgba(255, 255, 255, 8);
    font-size: 2rem;
    letter-spacing: 2px;
    color: rgb(255, 0, 149);
    // font-weight: 600;

}

.banner {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: url('../img/logo.jpg')bottom !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    margin-bottom: 0 !important;
}

.banner:before,
.sec2:before,
.contacts:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background: linear-gradient(#000, transparent);
    pointer-events: none;
}

.banner:after,
.sec2:after,
.contacts:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background: linear-gradient(360deg, #000, transparent);
    pointer-events: none;
}

.height100p {
    height: 100vh;
}

.h100 {
    // height: 100%;
}

.jumbotron {


    .contentBox {
        padding-top: 300px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-transform: capitalize;
        text-align: center;
        z-index: 10;

        h1 {
            font-weight: bold;
            color: $white;
            text-transform: uppercase;
            font-size: 5rem;
            background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
            background: linear-gradient(to right, #ef32d9, #89fffd);
            // background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
            // background: linear-gradient(to right, #f953c6, #b91d73);
            // background: linear-gradient(to right, #1e9600, #fff200, #ff0000);

            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-transform: uppercase;
            font-size: 6rem;
            letter-spacing: 45px;
        }

        p {
            color: $white;
            font-size: 1.3rem;
            letter-spacing: 20px;
        }

        .iconsBlock {
            padding-top: 100px;

            .cli {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                li {
                    list-style: none;
                    margin: 0 20px;

                    a {
                        color: rgba(255, 255, 255, 0.637);
                        font-size: 3em;
                        transition: 0.5s;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}



.contentBox {
    // padding-top: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    text-align: center;
    z-index: 10;

    h3 {

        background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
        background: linear-gradient(to right, #ef32d9, #89fffd);
        // background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
        // background: linear-gradient(to right, #f953c6, #b91d73);
        // background: linear-gradient(to right, #1e9600, #fff200, #ff0000);

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        font-size: 4rem;
        letter-spacing: 15px;
    }

}


.sec1 {
    padding: 100px 0;
    // background-image: radial-gradient(circle, #7a0046, #630a38, #4d0f2c, #370f20, #230d15, #1b090f, #110408, #000000, #000000, #000000, #000000, #000000);
    background: url('../img/bg.jpg') center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    .headerText {

        .girl {
            margin-bottom: 50px;
        }

        h2 {
            font-size: 2.5rem;
            color: $white;
            letter-spacing: 2px;
            margin-bottom: 30px;

            span {
                font-size: 4rem;
                color: red;
                letter-spacing: 15px;
            }
        }

        .working-time-block {

            display: flex;
            justify-content: space-around;

            .clock {
                width: 10%;
                margin-bottom: 30px;
            }

            .working-time {
                font-size: 3rem;
                margin-bottom: 30px;
                letter-spacing: 3px;
                color: white;

                span {
                    font-size: 3rem;
                    // color: rgb(255, 0, 149);
                    color: red;
                    font-weight: bold;
                }
            }
        }


        .phone-block {
            display: flex;
            // flex-direction: column;
            justify-content: center;
            align-items: center;

            .fa-phone {
                font-size: 3rem;
                color: green;
                margin-right: 20px;
            }

            .phone {
                font-size: 3rem;
                color: white;
                margin-left: 20px;
                margin-top: 10px;
            }
        }
    }
}



.massage {
    background: url('../img/banner5.jpg') center;
    height: 100vh;

    p {
        color: white;
        margin: 100px 0;
    }
}

.sec1-1 {
    background: black;

    .wrapper {
        width: 1170px;
        margin: 0 auto;


        .box {
            margin-bottom: 300px;



            .left-img {
                margin-top: 50px;
                width: 27%;
                float: left;
            }

            .right-img {
                margin-top: 250px;
                width: 35%;
                float: right;
                shape-outside: polygon(97px 758px, 147px 699px, 120px 573px, 98px 463px, 130px 409px, 182px 336px, 209px 257px, 316px 248px, 411px 1147px, 248px 1161px, 248px 959px, 107px 928px, 81px 840px);
            }

            .about {

                color: white;
                font-size: 2.5rem;
                text-align: center;

                span {
                    color: rgb(255, 0, 149);
                }
            }
        }
    }
}


.sec-1-3 {
    margin-top: 100px;
    height: 120vh;
    background: url('../img/bg2.jpg')center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    position: relative;

    .sec-1-3-header {
        top: 370px;
        position: absolute;
        font-size: 5rem;
        color: rgb(255, 0, 149);
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-shadow: 1px 5px 10px rgb(255, 0, 106);

    }

    .sec-1-3-about {
        top: 500px;
        position: absolute;
        font-size: 2.5rem;
        color: white;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        display: block;
        max-width: 1024px;
    }
}

.gallery {
    h4 {
        color: white !important;
    }
}

.owel-about {

    .item-one {
        position: relative;

        h3 {
            font-size: 3rem;
            position: absolute;
            bottom: 40%;
            right: 0;
            margin-right: 50px;
            display: block;
            width: 60%;

            span {
                color: rgb(255, 0, 149);
                font-weight: bold;
            }

        }

        p {
            font-size: 2rem;
            position: absolute;
            bottom: 10%;
            right: 0;
            margin-right: 50px;
            display: block;
            width: 60%;
            color: red;
        }
    }

    .item-two {
        position: relative;

        h3 {
            font-size: 3rem;
            position: absolute;
            bottom: 40%;
            right: 0;
            margin-right: 50px;
            display: block;
            width: 60%;
            color: white;

            span {
                color: rgb(255, 0, 149);
                font-weight: bold;
            }

        }

        p {
            font-size: 2rem;
            position: absolute;
            bottom: 10%;
            right: 0;
            margin-right: 50px;
            display: block;
            width: 60%;
            color: red;
        }
    }

    .item-three {
        position: relative;

        h3 {
            font-size: 2.5rem;
            position: absolute;
            bottom: 40%;
            left: 0;
            margin-left: 50px;
            display: block;
            width: 45%;
            color: white;

            span {
                color: rgb(255, 0, 149);
                font-weight: bold;
            }

        }

        p {
            font-size: 2rem;
            position: absolute;
            bottom: 10%;
            right: 0;
            margin-right: 50px;
            display: block;
            width: 60%;
            color: red;
        }
    }
}


.present {
    background: url('../img/bg.jpg');




    .present-header {
        background: url('../img/ass.png')right top no-repeat !important;
        background-size: cover;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            color: white;
            font-size: 4rem;


        }
    }



    .present-content {
        margin-top: 100px;
        display: flex;
        justify-content: space-around;


        .present-content-text {
            display: flex;
            // border: 1px solid white;
            flex-direction: column;




            p {
                text-align: center;
                color: whitesmoke;
                font-size: 1.5rem;
            }

            ul {
                color: rgb(255, 0, 149);
                font-size: 3rem;

                li {
                    margin: 50px 0;
                    margin-left: 50px;
                }
            }
        }
    }

    .present-content-image {

        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        // border: 1px solid white;
        justify-content: center;
        align-items: center;

        img {
            width: 50%;
            margin: 10px 0;
            box-shadow: 5px 5px 15px black;

        }
    }

    p {
        color: whitesmoke;
        font-size: 3rem;
        text-align: center;

        span {
            color: red;
        }


    }

    .present-500 {
        display: block;
        margin: 50px auto;
        width: 70%;
    }

}


.sec2 {
    position: relative;
    // background-image: radial-gradient(circle, #2a2a2a, #212121, #191919, #0f0f0f, #000000);
    background-image: radial-gradient(circle, #37052e, #2d0721, #230616, #17030b, #000000);
    margin-top: 50px;



    h3 {
        margin-bottom: 150px;
    }

    .blogPost {

        position: relative;
        margin-top: 30px;
        background: black;
        display: flex;
        flex-direction: row;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.472);

        .imgBx {
            height: 350px;
            flex-basis: 30%;
            background: black !important;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content {
            flex-basis: 70%;
            padding: 15px;
            height: 100%;
            position: relative;
            // background-image: radial-gradient(circle, #660000, #50040d, #390910, #22080d, #000000);
            background-image: radial-gradient(circle, #3e0000, #320406, #250408, #180206, #000000);


            h2 {
                margin: 0;
                padding: 0 0 10px;
                font-size: 1.5rem;
                color: rgb(255, 0, 149);
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            }

            .card-about {
                margin-top: 10px;
                color: white !important;
                letter-spacing: 1px;
                font-weight: 300;
            }

            .card-time {
                color: rgb(124, 124, 124);
                letter-spacing: 1px;

            }


            .btnD2 {
                background: $btnBackground;
                color: green;
                border-radius: 30px;
                padding: 10px 20px;
                float: right;
                transition: 0.5s;
                display: block !important;
                font-weight: bold;

            }
        }
    }
}


.blog {
    position: relative;
    padding: 100px 0;
    background: $black;


    .headText {
        h2 {
            font-size: 2.5rem;
            color: $white;
            text-align: center;
            padding: 20px 0;
        }

        p {
            color: $white;
            text-align: center;
            font-size: 1.15rem;
            padding: 20px 0;
        }
    }
}




.contacts {
    position: relative;
    padding: 100px 0;
    // background: url('../img/bg.jpg');
    // background-size: cover;
    background-image: radial-gradient(circle, #6a004e, #520837, #390d24, #220b14, #000000);
    min-height: 100vh;


    .headerText {

        h2 {
            font-size: 2.5rem;
            color: $white;
            text-align: center;
            padding: 20px 0;
        }

        p {
            color: $white;
            text-align: center;
            font-size: 1.15rem;
            padding: 20px 0;
        }
    }

    form {
        margin-top: 20px;
        position: relative;
        z-index: 1000;
    }

    .form {

        background: rgba(119, 119, 119, 0.123);
        padding: 15px;
        border-radius: 15px;
        box-shadow: 1px 1px 5px black;
        border: 1px solid rgba(255, 255, 255, 0.2);



        // #42132B

        .form-control {
            background: transparent;
            border: none;
            border-bottom: 1px dotted rgba(255, 255, 255, .3);
            height: 50px;
            border-radius: 5px;
            // background: rgba(0, 0, 0, .6);
            background: linear-gradient(360deg, rgba(150, 151, 151, 0.2), transparent);
            color: $white;
            font-size: 1.25rem;
            outline: none;

            &:focus,
            &:hover {
                border: none;
                border-bottom: 1px dotted rgba(255, 255, 255, 1);
                box-shadow: none;

            }
        }

        label {
            color: $white;
            opacity: 0.6;
            font-size: 1.25rem;
        }

        .btnD1 {
            border: 1px solid rgba(255, 255, 255, 0.438);
            color: $white;
            background: rgba(255, 255, 255, 0.089);
            border-radius: 50px;
            padding: 15px 50px;
            font-size: 1.15rem;
            outline: none;
            font-weight: 500;
            margin-top: 20px;
            transition: 0.5s all;

            &:hover {
                background: rgba(255, 255, 255, 1);
                color: $black;
            }
        }
    }
}


footer {
    background: $black;

    .cli {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            list-style: none;
            margin: 0 20px;

            a {
                color: #777;
                font-size: 2em;
                transition: 0.5s;

                &:hover {
                    color: $white;
                }

            }
        }
    }

    .cpryt {
        padding-top: 20px;
        text-align: center;
        color: #777;
        margin-bottom: 50px;
    }
}


@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@media (max-width: 992px) {

    .navbar {

        .fas {
            // color: rgb(20, 150, 89);
            // font-size: 1.5em !important;
            // // position: relative;
            // left: -50px;
        }

    }

    .navbar-light .navbar-toggler {
        // background: rgb(20, 150, 89);
        background: rgb(255, 0, 149);
        // border: 1px solid rgb(255, 0, 149);
        // border-radius: 5px;

        .navbar-toggler-icon {
            color: rgb(255, 0, 149) !important;
        }
    }

    .banner {
        height: 100vh;
        padding: 100px 0;
    }

    .contentBox h1 {
        // padding-top: 350px;
        font-size: 2rem;
    }

    .iconsBlock {
        .cli {
            li {
                a {
                    font-size: 2em !important;
                }
            }
        }
    }

    .nav-link {
        padding-left: 10px !important;
    }

    .contentBox {
        padding-top: 0;
    }

    .blog {
        padding: 0;
    }

    .sec1 {
        padding: 0;
    }

    .blogPost {
        background: black !important;

        .imgBx {
            height: 200px;
            background: black !important;
        }

        .content {
            h2 {
                font-size: 1.2rem;
            }

            p {
                font-size: 1rem;
            }
        }
    }

    footer {
        .cli {
            li {
                padding: 0 10px;
                margin: 0;
            }
        }
    }
}

@media (max-width: 500px) {

    .navbar-brand {
        font-size: 20px !important;
        margin-right: 5px !important;
    }


    .navbar {
        // background: transparent !important;
        background: rgba(0, 0, 0, 0.753) !important;
        transition: 1s;

        .fas {
            color: rgb(68, 182, 102);
            font-size: 1rem !important;
            letter-spacing: 3px;
            display: block !important;
            margin-right: 5px;

        }

        .phone {
            color: white;
            margin-bottom: 0;
            margin-right: 5px;
            font-size: 14px;
            display: block !important;
        }

        .location {
            color: white;
            margin-bottom: 0;
            margin-right: 5px;
            font-size: 14px;
            display: block !important;
        }
    }

    .jumbotron {


        .contentBox {
            padding-top: 200px;


            h1 {
                font-size: 4rem !important;
                letter-spacing: 15px;
            }

            p {
                letter-spacing: 5px;
            }

            .iconsBlock {
                padding-top: 50px;
            }
        }
    }

    .contentBox {


        h1 {
            font-size: 3rem;
            letter-spacing: 20px;
            margin-bottom: 50px;
        }

        p {
            font-size: 1rem;
            letter-spacing: 5px;
        }
    }

    .sec1 {
        // background-size: 500% !important;
        margin-bottom: 100px;


        h2 {
            font-size: 1.3rem !important;
            line-height: 1.8;
            margin-top: 100px;

            span {
                font-size: 2rem !important;

            }

        }


        .working-time-block {

            display: flex;
            justify-content: space-around;

            .clock {
                width: 12%;
                height: 12%;
                margin-bottom: 30px;
            }

            .working-time {
                font-size: 1.5rem !important;
                margin-bottom: 30px;
                letter-spacing: 3px;
                color: white;

                span {
                    font-size: 1.5rem !important;
                    // color: rgb(255, 0, 149);
                    color: red;
                    font-weight: bold;
                }
            }
        }


        .phone-block {
            display: flex;
            justify-content: center;
            align-items: center;

            .fa-phone {
                font-size: 2rem !important;
                color: green;
                margin-right: 20px;
            }

            .phone {
                font-size: 2rem !important;
                color: white;
                margin-left: 20px;
                margin-top: 10px;
            }
        }
    }

    .sec1-1 {
        background: black;
        margin-bottom: 0 !important;

        .wrapper {
            width: 90%;
            margin: 0 auto;

            .box {
                margin-bottom: 0;


                .left-img {
                    margin-top: 50px;
                    margin-left: -25px;
                    width: 45%;
                    float: left;
                    shape-outside: polygon(8px 11px, 130px 16px, 128px 111px, 121px 131px, 103px 188px, 128px 246px, 138px 259px, 168px 289px, 155px 477px, 0px 475px);
                }

                .right-img {
                    display: none !important;
                    margin-top: 20px;
                    width: 15%;
                    shape-outside: none;
                }

                .about {
                    margin: right !important;
                    color: white;
                    font-size: 1.2rem;
                    text-align: center;

                    span {
                        color: rgb(255, 0, 149);
                    }
                }
            }
        }
    }


    .sec-1-3 {
        margin-top: 0 !important;
        height: 120vh;
        background: url('../img/bg2.jpg')center !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        position: relative;
        margin-bottom: 50px;

        .sec-1-3-header {
            top: 380px;
            position: absolute;
            font-size: 3rem;
            color: rgb(255, 0, 149);
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-shadow: 1px 5px 10px rgb(255, 0, 106);

        }

        .sec-1-3-about {
            top: 620px;
            position: absolute;
            font-size: 1.2rem;
            color: white;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            display: block;
            max-width: 1024px;
        }
    }

    .owel-about {

        img {
            display: block;
            box-sizing: border-box;
            height: 60vh !important;
            width: 150% !important;

        }

        .item-one {
            position: relative;

            h3 {
                font-size: 1.2rem;
                position: absolute;
                bottom: 22%;
                right: 0;
                margin-right: 10px;
                display: block;
                width: 50%;

                span {
                    color: rgb(255, 0, 149);
                    font-weight: bold;
                }

            }

            p {
                font-size: 0.8rem;
                position: absolute;
                bottom: 5%;
                right: 0;
                margin-right: 0;
                display: block;
                width: 50%;
                color: red;
            }
        }

        .item-two {
            position: relative;

            h3 {
                font-size: 1.2rem;
                position: absolute;
                bottom: 22%;
                right: 0;
                margin-right: 10px;
                display: block;
                width: 60%;
                color: white;

                span {
                    color: rgb(255, 0, 149);
                    font-weight: bold;
                }

            }

            p {
                font-size: 0.8rem;
                position: absolute;
                bottom: 5%;
                right: 0;
                margin-right: 5px;
                display: block;
                width: 60%;
                color: red;
            }
        }

        .item-three {
            position: relative;

            h3 {
                font-size: 1.2rem;
                position: absolute;
                bottom: 15%;
                left: 0;
                margin-left: 50px;
                display: block;
                width: 60%;
                color: white;

                span {
                    color: rgb(255, 0, 149);
                    font-weight: bold;
                }

            }

            p {
                font-size: 0.8rem;
                position: absolute;
                bottom: 5%;
                right: 0;
                margin-right: 50px;
                display: block;
                width: 60%;
                color: red;
            }
        }
    }


    .present {
        background: url('../img/bg.jpg');


        .present-header {
            background: url('../img/ass.png') 200px top no-repeat !important;
            background-size: 70% !important;
            height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                margin-bottom: 100px;
                color: white;
                font-size: 1.8rem;
                // text-align: left;
            }
        }



        .present-content {
            margin-top: 0px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;


            .present-content-text {
                display: flex;
                flex-direction: column;

                p {
                    text-align: center;
                    color: whitesmoke;
                    font-size: 1.8rem;
                }

                ul {
                    color: rgb(255, 0, 149);
                    font-size: 2rem;
                    display: flex;
                    flex-direction: column;

                    li {
                        margin: 5px auto;

                    }
                }
            }
        }

        .present-content-image {

            margin-bottom: 100px;
            display: flex;
            flex-direction: column;
            // border: 1px solid white;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                margin: 10px 0;
                box-shadow: 5px 5px 15px black;

            }
        }

        p {
            color: whitesmoke;
            font-size: 1.5rem;
            text-align: center;

            span {
                color: red;
            }


        }

        .present-500 {
            display: block;
            margin: 100px auto;
            width: 100%;
        }

    }


    .sec2 {
        h3 {
            font-size: 3rem;
            letter-spacing: 2px;
            margin-bottom: 50px;
        }

        .d-flex {
            display: block !important;

            .blogPost {
                flex-direction: column;

                .imgBx {
                    height: 200px;
                    background: black !important;


                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .content {

                    .card-about {
                        font-size: 0.8rem !important;
                    }

                }
            }
        }
    }
}