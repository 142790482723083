@font-face {
  font-family: 'roboto_condensedregular';
  src: url("/fonts/robotocondensed-regular-webfont.woff2") format("woff2"), url("/fonts/robotocondensed-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'roboto_condensedbold';
  src: url("/fonts/robotocondensed-bold-webfont.woff2") format("woff2"), url("/fonts/robotocondensed-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'roboto_condensedbold_italic';
  src: url("/fonts/robotocondensed-bolditalic-webfont.woff2") format("woff2"), url("/fonts/robotocondensed-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'roboto_condenseditalic';
  src: url("/fonts/robotocondensed-italic-webfont.woff2") format("woff2"), url("/fonts/robotocondensed-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'roboto_condensedlight_italic';
  src: url("/fonts/robotocondensed-lightitalic-webfont.woff2") format("woff2"), url("/fonts/robotocondensed-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'roboto_condensedlight';
  src: url("/fonts/robotocondensed-light-webfont.woff2") format("woff2"), url("/fonts/robotocondensed-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

body,
html {
  overflow-x: hidden;
  background: black;
  font-family: 'roboto_condensedlight'; }

* {
  outline: none !important;
  scroll-behavior: smooth; }

header {
  position: relative;
  z-index: 1000000; }

p {
  font-size: 1.15em; }

.navbar {
  background: rgba(0, 0, 0, 0.753) !important;
  transition: 1s; }
  .navbar .fas {
    color: #44b666;
    font-size: 1.2rem;
    letter-spacing: 3px;
    margin-right: 30px; }
  .navbar .phone {
    color: white;
    margin-bottom: 0;
    margin-right: 50px;
    font-size: 20px; }
  .navbar .location {
    color: white;
    margin-bottom: 0;
    margin-right: 50px;
    font-size: 20px; }

.scrolled {
  background: rgba(0, 0, 0, 0.753) !important;
  transition: 1s; }

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #ff0095;
  border: 1px solid #ff0095;
  border-radius: 5px; }

.navbar-light .navbar-nav .active > .nav-link:hover {
  color: #000; }

.navbar-light .navbar-nav .nav-link {
  color: white; }

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #ff0095; }

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:visited {
  color: white;
  font-size: 2rem;
  letter-spacing: 2px;
  color: #ff0095; }

.banner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url("../img/logo.jpg") bottom !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  margin-bottom: 0 !important; }

.banner:before,
.sec2:before,
.contacts:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background: linear-gradient(#000, transparent);
  pointer-events: none; }

.banner:after,
.sec2:after,
.contacts:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background: linear-gradient(360deg, #000, transparent);
  pointer-events: none; }

.height100p {
  height: 100vh; }

.jumbotron .contentBox {
  padding-top: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
  z-index: 10; }
  .jumbotron .contentBox h1 {
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    font-size: 5rem;
    background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
    background: linear-gradient(to right, #ef32d9, #89fffd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-size: 6rem;
    letter-spacing: 45px; }
  .jumbotron .contentBox p {
    color: #fff;
    font-size: 1.3rem;
    letter-spacing: 20px; }
  .jumbotron .contentBox .iconsBlock {
    padding-top: 100px; }
    .jumbotron .contentBox .iconsBlock .cli {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .jumbotron .contentBox .iconsBlock .cli li {
        list-style: none;
        margin: 0 20px; }
        .jumbotron .contentBox .iconsBlock .cli li a {
          color: rgba(255, 255, 255, 0.637);
          font-size: 3em;
          transition: 0.5s; }
          .jumbotron .contentBox .iconsBlock .cli li a:hover {
            color: #fff; }

.contentBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
  z-index: 10; }
  .contentBox h3 {
    background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
    background: linear-gradient(to right, #ef32d9, #89fffd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-size: 4rem;
    letter-spacing: 15px; }

.sec1 {
  padding: 100px 0;
  background: url("../img/bg.jpg") center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important; }
  .sec1 .headerText .girl {
    margin-bottom: 50px; }
  .sec1 .headerText h2 {
    font-size: 2.5rem;
    color: #fff;
    letter-spacing: 2px;
    margin-bottom: 30px; }
    .sec1 .headerText h2 span {
      font-size: 4rem;
      color: red;
      letter-spacing: 15px; }
  .sec1 .headerText .working-time-block {
    display: flex;
    justify-content: space-around; }
    .sec1 .headerText .working-time-block .clock {
      width: 10%;
      margin-bottom: 30px; }
    .sec1 .headerText .working-time-block .working-time {
      font-size: 3rem;
      margin-bottom: 30px;
      letter-spacing: 3px;
      color: white; }
      .sec1 .headerText .working-time-block .working-time span {
        font-size: 3rem;
        color: red;
        font-weight: bold; }
  .sec1 .headerText .phone-block {
    display: flex;
    justify-content: center;
    align-items: center; }
    .sec1 .headerText .phone-block .fa-phone {
      font-size: 3rem;
      color: green;
      margin-right: 20px; }
    .sec1 .headerText .phone-block .phone {
      font-size: 3rem;
      color: white;
      margin-left: 20px;
      margin-top: 10px; }

.massage {
  background: url("../img/banner5.jpg") center;
  height: 100vh; }
  .massage p {
    color: white;
    margin: 100px 0; }

.sec1-1 {
  background: black; }
  .sec1-1 .wrapper {
    width: 1170px;
    margin: 0 auto; }
    .sec1-1 .wrapper .box {
      margin-bottom: 300px; }
      .sec1-1 .wrapper .box .left-img {
        margin-top: 50px;
        width: 27%;
        float: left; }
      .sec1-1 .wrapper .box .right-img {
        margin-top: 250px;
        width: 35%;
        float: right;
        shape-outside: polygon(97px 758px, 147px 699px, 120px 573px, 98px 463px, 130px 409px, 182px 336px, 209px 257px, 316px 248px, 411px 1147px, 248px 1161px, 248px 959px, 107px 928px, 81px 840px); }
      .sec1-1 .wrapper .box .about {
        color: white;
        font-size: 2.5rem;
        text-align: center; }
        .sec1-1 .wrapper .box .about span {
          color: #ff0095; }

.sec-1-3 {
  margin-top: 100px;
  height: 120vh;
  background: url("../img/bg2.jpg") center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  position: relative; }
  .sec-1-3 .sec-1-3-header {
    top: 370px;
    position: absolute;
    font-size: 5rem;
    color: #ff0095;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-shadow: 1px 5px 10px #ff006a; }
  .sec-1-3 .sec-1-3-about {
    top: 500px;
    position: absolute;
    font-size: 2.5rem;
    color: white;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: block;
    max-width: 1024px; }

.gallery h4 {
  color: white !important; }

.owel-about .item-one {
  position: relative; }
  .owel-about .item-one h3 {
    font-size: 3rem;
    position: absolute;
    bottom: 40%;
    right: 0;
    margin-right: 50px;
    display: block;
    width: 60%; }
    .owel-about .item-one h3 span {
      color: #ff0095;
      font-weight: bold; }
  .owel-about .item-one p {
    font-size: 2rem;
    position: absolute;
    bottom: 10%;
    right: 0;
    margin-right: 50px;
    display: block;
    width: 60%;
    color: red; }

.owel-about .item-two {
  position: relative; }
  .owel-about .item-two h3 {
    font-size: 3rem;
    position: absolute;
    bottom: 40%;
    right: 0;
    margin-right: 50px;
    display: block;
    width: 60%;
    color: white; }
    .owel-about .item-two h3 span {
      color: #ff0095;
      font-weight: bold; }
  .owel-about .item-two p {
    font-size: 2rem;
    position: absolute;
    bottom: 10%;
    right: 0;
    margin-right: 50px;
    display: block;
    width: 60%;
    color: red; }

.owel-about .item-three {
  position: relative; }
  .owel-about .item-three h3 {
    font-size: 2.5rem;
    position: absolute;
    bottom: 40%;
    left: 0;
    margin-left: 50px;
    display: block;
    width: 45%;
    color: white; }
    .owel-about .item-three h3 span {
      color: #ff0095;
      font-weight: bold; }
  .owel-about .item-three p {
    font-size: 2rem;
    position: absolute;
    bottom: 10%;
    right: 0;
    margin-right: 50px;
    display: block;
    width: 60%;
    color: red; }

.present {
  background: url("../img/bg.jpg"); }
  .present .present-header {
    background: url("../img/ass.png") right top no-repeat !important;
    background-size: cover;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .present .present-header h3 {
      color: white;
      font-size: 4rem; }
  .present .present-content {
    margin-top: 100px;
    display: flex;
    justify-content: space-around; }
    .present .present-content .present-content-text {
      display: flex;
      flex-direction: column; }
      .present .present-content .present-content-text p {
        text-align: center;
        color: whitesmoke;
        font-size: 1.5rem; }
      .present .present-content .present-content-text ul {
        color: #ff0095;
        font-size: 3rem; }
        .present .present-content .present-content-text ul li {
          margin: 50px 0;
          margin-left: 50px; }
  .present .present-content-image {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .present .present-content-image img {
      width: 50%;
      margin: 10px 0;
      box-shadow: 5px 5px 15px black; }
  .present p {
    color: whitesmoke;
    font-size: 3rem;
    text-align: center; }
    .present p span {
      color: red; }
  .present .present-500 {
    display: block;
    margin: 50px auto;
    width: 70%; }

.sec2 {
  position: relative;
  background-image: radial-gradient(circle, #37052e, #2d0721, #230616, #17030b, #000000);
  margin-top: 50px; }
  .sec2 h3 {
    margin-bottom: 150px; }
  .sec2 .blogPost {
    position: relative;
    margin-top: 30px;
    background: black;
    display: flex;
    flex-direction: row;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.472); }
    .sec2 .blogPost .imgBx {
      height: 350px;
      flex-basis: 30%;
      background: black !important; }
      .sec2 .blogPost .imgBx img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .sec2 .blogPost .content {
      flex-basis: 70%;
      padding: 15px;
      height: 100%;
      position: relative;
      background-image: radial-gradient(circle, #3e0000, #320406, #250408, #180206, #000000); }
      .sec2 .blogPost .content h2 {
        margin: 0;
        padding: 0 0 10px;
        font-size: 1.5rem;
        color: #ff0095;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
      .sec2 .blogPost .content .card-about {
        margin-top: 10px;
        color: white !important;
        letter-spacing: 1px;
        font-weight: 300; }
      .sec2 .blogPost .content .card-time {
        color: #7c7c7c;
        letter-spacing: 1px; }
      .sec2 .blogPost .content .btnD2 {
        background: #262626;
        color: green;
        border-radius: 30px;
        padding: 10px 20px;
        float: right;
        transition: 0.5s;
        display: block !important;
        font-weight: bold; }

.blog {
  position: relative;
  padding: 100px 0;
  background: #000; }
  .blog .headText h2 {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    padding: 20px 0; }
  .blog .headText p {
    color: #fff;
    text-align: center;
    font-size: 1.15rem;
    padding: 20px 0; }

.contacts {
  position: relative;
  padding: 100px 0;
  background-image: radial-gradient(circle, #6a004e, #520837, #390d24, #220b14, #000000);
  min-height: 100vh; }
  .contacts .headerText h2 {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    padding: 20px 0; }
  .contacts .headerText p {
    color: #fff;
    text-align: center;
    font-size: 1.15rem;
    padding: 20px 0; }
  .contacts form {
    margin-top: 20px;
    position: relative;
    z-index: 1000; }
  .contacts .form {
    background: rgba(119, 119, 119, 0.123);
    padding: 15px;
    border-radius: 15px;
    box-shadow: 1px 1px 5px black;
    border: 1px solid rgba(255, 255, 255, 0.2); }
    .contacts .form .form-control {
      background: transparent;
      border: none;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
      height: 50px;
      border-radius: 5px;
      background: linear-gradient(360deg, rgba(150, 151, 151, 0.2), transparent);
      color: #fff;
      font-size: 1.25rem;
      outline: none; }
      .contacts .form .form-control:focus, .contacts .form .form-control:hover {
        border: none;
        border-bottom: 1px dotted white;
        box-shadow: none; }
    .contacts .form label {
      color: #fff;
      opacity: 0.6;
      font-size: 1.25rem; }
    .contacts .form .btnD1 {
      border: 1px solid rgba(255, 255, 255, 0.438);
      color: #fff;
      background: rgba(255, 255, 255, 0.089);
      border-radius: 50px;
      padding: 15px 50px;
      font-size: 1.15rem;
      outline: none;
      font-weight: 500;
      margin-top: 20px;
      transition: 0.5s all; }
      .contacts .form .btnD1:hover {
        background: white;
        color: #000; }

footer {
  background: #000; }
  footer .cli {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    footer .cli li {
      list-style: none;
      margin: 0 20px; }
      footer .cli li a {
        color: #777;
        font-size: 2em;
        transition: 0.5s; }
        footer .cli li a:hover {
          color: #fff; }
  footer .cpryt {
    padding-top: 20px;
    text-align: center;
    color: #777;
    margin-bottom: 50px; }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media (max-width: 992px) {
  .navbar-light .navbar-toggler {
    background: #ff0095; }
    .navbar-light .navbar-toggler .navbar-toggler-icon {
      color: #ff0095 !important; }
  .banner {
    height: 100vh;
    padding: 100px 0; }
  .contentBox h1 {
    font-size: 2rem; }
  .iconsBlock .cli li a {
    font-size: 2em !important; }
  .nav-link {
    padding-left: 10px !important; }
  .contentBox {
    padding-top: 0; }
  .blog {
    padding: 0; }
  .sec1 {
    padding: 0; }
  .blogPost {
    background: black !important; }
    .blogPost .imgBx {
      height: 200px;
      background: black !important; }
    .blogPost .content h2 {
      font-size: 1.2rem; }
    .blogPost .content p {
      font-size: 1rem; }
  footer .cli li {
    padding: 0 10px;
    margin: 0; } }

@media (max-width: 500px) {
  .navbar-brand {
    font-size: 20px !important;
    margin-right: 5px !important; }
  .navbar {
    background: rgba(0, 0, 0, 0.753) !important;
    transition: 1s; }
    .navbar .fas {
      color: #44b666;
      font-size: 1rem !important;
      letter-spacing: 3px;
      display: block !important;
      margin-right: 5px; }
    .navbar .phone {
      color: white;
      margin-bottom: 0;
      margin-right: 5px;
      font-size: 14px;
      display: block !important; }
    .navbar .location {
      color: white;
      margin-bottom: 0;
      margin-right: 5px;
      font-size: 14px;
      display: block !important; }
  .jumbotron .contentBox {
    padding-top: 200px; }
    .jumbotron .contentBox h1 {
      font-size: 4rem !important;
      letter-spacing: 15px; }
    .jumbotron .contentBox p {
      letter-spacing: 5px; }
    .jumbotron .contentBox .iconsBlock {
      padding-top: 50px; }
  .contentBox h1 {
    font-size: 3rem;
    letter-spacing: 20px;
    margin-bottom: 50px; }
  .contentBox p {
    font-size: 1rem;
    letter-spacing: 5px; }
  .sec1 {
    margin-bottom: 100px; }
    .sec1 h2 {
      font-size: 1.3rem !important;
      line-height: 1.8;
      margin-top: 100px; }
      .sec1 h2 span {
        font-size: 2rem !important; }
    .sec1 .working-time-block {
      display: flex;
      justify-content: space-around; }
      .sec1 .working-time-block .clock {
        width: 12%;
        height: 12%;
        margin-bottom: 30px; }
      .sec1 .working-time-block .working-time {
        font-size: 1.5rem !important;
        margin-bottom: 30px;
        letter-spacing: 3px;
        color: white; }
        .sec1 .working-time-block .working-time span {
          font-size: 1.5rem !important;
          color: red;
          font-weight: bold; }
    .sec1 .phone-block {
      display: flex;
      justify-content: center;
      align-items: center; }
      .sec1 .phone-block .fa-phone {
        font-size: 2rem !important;
        color: green;
        margin-right: 20px; }
      .sec1 .phone-block .phone {
        font-size: 2rem !important;
        color: white;
        margin-left: 20px;
        margin-top: 10px; }
  .sec1-1 {
    background: black;
    margin-bottom: 0 !important; }
    .sec1-1 .wrapper {
      width: 90%;
      margin: 0 auto; }
      .sec1-1 .wrapper .box {
        margin-bottom: 0; }
        .sec1-1 .wrapper .box .left-img {
          margin-top: 50px;
          margin-left: -25px;
          width: 45%;
          float: left;
          shape-outside: polygon(8px 11px, 130px 16px, 128px 111px, 121px 131px, 103px 188px, 128px 246px, 138px 259px, 168px 289px, 155px 477px, 0px 475px); }
        .sec1-1 .wrapper .box .right-img {
          display: none !important;
          margin-top: 20px;
          width: 15%;
          shape-outside: none; }
        .sec1-1 .wrapper .box .about {
          margin: right !important;
          color: white;
          font-size: 1.2rem;
          text-align: center; }
          .sec1-1 .wrapper .box .about span {
            color: #ff0095; }
  .sec-1-3 {
    margin-top: 0 !important;
    height: 120vh;
    background: url("../img/bg2.jpg") center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    position: relative;
    margin-bottom: 50px; }
    .sec-1-3 .sec-1-3-header {
      top: 380px;
      position: absolute;
      font-size: 3rem;
      color: #ff0095;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-shadow: 1px 5px 10px #ff006a; }
    .sec-1-3 .sec-1-3-about {
      top: 620px;
      position: absolute;
      font-size: 1.2rem;
      color: white;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      display: block;
      max-width: 1024px; }
  .owel-about img {
    display: block;
    box-sizing: border-box;
    height: 60vh !important;
    width: 150% !important; }
  .owel-about .item-one {
    position: relative; }
    .owel-about .item-one h3 {
      font-size: 1.2rem;
      position: absolute;
      bottom: 22%;
      right: 0;
      margin-right: 10px;
      display: block;
      width: 50%; }
      .owel-about .item-one h3 span {
        color: #ff0095;
        font-weight: bold; }
    .owel-about .item-one p {
      font-size: 0.8rem;
      position: absolute;
      bottom: 5%;
      right: 0;
      margin-right: 0;
      display: block;
      width: 50%;
      color: red; }
  .owel-about .item-two {
    position: relative; }
    .owel-about .item-two h3 {
      font-size: 1.2rem;
      position: absolute;
      bottom: 22%;
      right: 0;
      margin-right: 10px;
      display: block;
      width: 60%;
      color: white; }
      .owel-about .item-two h3 span {
        color: #ff0095;
        font-weight: bold; }
    .owel-about .item-two p {
      font-size: 0.8rem;
      position: absolute;
      bottom: 5%;
      right: 0;
      margin-right: 5px;
      display: block;
      width: 60%;
      color: red; }
  .owel-about .item-three {
    position: relative; }
    .owel-about .item-three h3 {
      font-size: 1.2rem;
      position: absolute;
      bottom: 15%;
      left: 0;
      margin-left: 50px;
      display: block;
      width: 60%;
      color: white; }
      .owel-about .item-three h3 span {
        color: #ff0095;
        font-weight: bold; }
    .owel-about .item-three p {
      font-size: 0.8rem;
      position: absolute;
      bottom: 5%;
      right: 0;
      margin-right: 50px;
      display: block;
      width: 60%;
      color: red; }
  .present {
    background: url("../img/bg.jpg"); }
    .present .present-header {
      background: url("../img/ass.png") 200px top no-repeat !important;
      background-size: 70% !important;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center; }
      .present .present-header h3 {
        margin-bottom: 100px;
        color: white;
        font-size: 1.8rem; }
    .present .present-content {
      margin-top: 0px;
      display: flex;
      justify-content: space-around;
      flex-direction: column; }
      .present .present-content .present-content-text {
        display: flex;
        flex-direction: column; }
        .present .present-content .present-content-text p {
          text-align: center;
          color: whitesmoke;
          font-size: 1.8rem; }
        .present .present-content .present-content-text ul {
          color: #ff0095;
          font-size: 2rem;
          display: flex;
          flex-direction: column; }
          .present .present-content .present-content-text ul li {
            margin: 5px auto; }
    .present .present-content-image {
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .present .present-content-image img {
        width: 100%;
        margin: 10px 0;
        box-shadow: 5px 5px 15px black; }
    .present p {
      color: whitesmoke;
      font-size: 1.5rem;
      text-align: center; }
      .present p span {
        color: red; }
    .present .present-500 {
      display: block;
      margin: 100px auto;
      width: 100%; }
  .sec2 h3 {
    font-size: 3rem;
    letter-spacing: 2px;
    margin-bottom: 50px; }
  .sec2 .d-flex {
    display: block !important; }
    .sec2 .d-flex .blogPost {
      flex-direction: column; }
      .sec2 .d-flex .blogPost .imgBx {
        height: 200px;
        background: black !important; }
        .sec2 .d-flex .blogPost .imgBx img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .sec2 .d-flex .blogPost .content .card-about {
        font-size: 0.8rem !important; } }
